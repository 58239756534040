import { AnimatedShape, Button, FixedShape, Tag } from '@components';
import { useTabletMediaQuery } from '@hooks';
import { gsap } from 'gsap';
import { GetStaticProps, NextPage } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import styles from '../styles/page404.module.scss';

const Page404: NextPage = () => {
  const { t } = useTranslation('common');
  const isTablet = useTabletMediaQuery();
  const router = useRouter();
  const animation = useRef<GSAPTimeline>();
  const tagRef = useRef(null);
  const titleLine1Ref = useRef(null);
  const titleLine2Ref = useRef(null);

  useEffect(() => {
    animation.current = gsap
      .timeline()
      .titleIn(titleLine1Ref.current)
      .titleIn(titleLine2Ref.current, 0.5)
      .fromTo(tagRef.current, { opacity: 0 }, { opacity: 1, delay: 1 }, 0);
  }, []);

  return (
    <div className={styles.mainContainer}>
      <FixedShape />
      {!isTablet && (
        <>
          <div className={styles.leftVertical}>{t('page_404.lateral')}</div>
          <div className={styles.rightVertical}>{t('page_404.lateral')}</div>
        </>
      )}
      <section className={styles.section}>
        <div className={styles.animatedShape}>
          <AnimatedShape />
        </div>
        <div className={styles.contentWrapper}>
          <div ref={tagRef} className={styles.tag}>
            <Tag mode="dark" text={t('page_404.tag')} />
          </div>
          <div className={styles.textWrapper}>
            <div ref={titleLine1Ref} className={styles.titleLine}>
              <div className={styles.titlePrimary}>404:</div>
            </div>
            <div ref={titleLine2Ref} className={styles.titleLine}>
              <div className={styles.titlePrimary}>{t('page_404.title')}</div>
            </div>
          </div>
          <div className={styles.cta}>
            <Button onClick={() => router.push('/')} variant="secondary" delay={3}>
              {t('page_404.cta')}
            </Button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Page404;

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: { ...(await serverSideTranslations(locale!, ['common'])) },
    revalidate: 1,
  };
};
